<template>
  <div class="SetWorkTime">
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称" style="margin-bottom: 0">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="排班类型">
          <span
            class="filterType"
            @click="currentWorkTime = 0"
            :class="[currentWorkTime == 0 ? 'filterTypeActive' : '']"
            >全部</span
          >
          <span
            class="filterType"
            @click="currentWorkTime = null"
            :class="[currentWorkTime == null ? 'filterTypeActive' : '']"
            >未排班</span
          >
          <span
            v-for="(item) in workTimeList"
            :key="item.wmId"
            class="filterType"
            @click="currentWorkTime = item.wmId"
            :class="[currentWorkTime == item.wmId ? 'filterTypeActive' : '']"
            >{{ item.wmName }}</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank"></div>

    <!-- 部门或班组的排班列表 -->
    <div class="wrap">
      <el-table :data="deptWorkTimeList" style="width: 100%">
        <el-table-column prop="labourGroupName" label="部门/班组名称"></el-table-column>
        <el-table-column
          prop="leader"
          label="负责人"
        ></el-table-column>
        <el-table-column
          prop="wmName"
          label="当前班次"
        ></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="setWorkTimeItem(scope.row)"
              >排班
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 班次的选择框 -->
    <el-dialog title="请选择班次" :visible.sync="showWorkTimeSelect" :before-close="closeWorkTimeItem">
      <el-table :data="workTimeList" style="width: 100%">
        <el-table-column prop="wmName" label="班次名称"></el-table-column>
        <el-table-column
          prop="wmOfficeHours"
          label="上班时间"
        ></el-table-column>
        <el-table-column
          prop="wmClosingTime"
          label="下班时间"
        ></el-table-column>
        <el-table-column prop="remark" label="班次类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">白班</span>
            <span v-if="scope.row.type == 1">夜班</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="updateWorkTimeItem(scope.row)"
              >设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <div class="wrap-blank"></div>
  </div>
</template>

<style lang="less" scope>
.SetWorkTime {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "SetWorkTime",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      projectList: [],

      currentUnit: null,
      unitList: [],
      
      workTimeList: [],
      currentWorkTime: null,
      currentWorkTimeItem: null,

      deptWorkTimeList: [],

      showWorkTimeSelect: false,

      pageNum: 1,
      pageSize: 10,
      totals: 0,
    };
  },
  computed: {
    
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.getUnitList() // 加载单位列表数据
      this.getWrokTimeList() // 加载项目所有排版列表
      this.getDeptWorkTimeList() // 加载部门/班组的排班状态
      this.currentUnit = null
      this.currentWorkTime = 0
    },
    currentUnit: function (val) {
      this.getDeptWorkTimeList() // 加载部门/班组的排班状态
      this.currentWorkTime = 0
    },
    currentWorkTime: function (val) {
      this.getDeptWorkTimeList() // 加载部门/班组的排班状态
    }
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data
        })
        .then((res) => {
          that.unitList = res.data.depts;
          if (res.data.depts.length) {
            that.currentUnit = res.data.depts[0].id
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目所有排版列表
    getWrokTimeList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/queryList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getWrokTimeList =>", res);
          that.workTimeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载部门/班组的排班状态
    getDeptWorkTimeList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
        deptId :that.currentUnit,
        wmId: that.currentWorkTime
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/queryGroupByWM",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getDeptWorkTimeList =>", res);
          that.deptWorkTimeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开对部门班组重新排班窗口
    setWorkTimeItem: function (data) {
      this.showWorkTimeSelect = true
      this.currentWorkTimeItem = data
    },
    // 关闭对部门班组重新排班窗口
    closeWorkTimeItem: function () {
      this.showWorkTimeSelect = false
      this.currentWorkTimeItem = null
    },
    // 更新对部门班组重新排班
    updateWorkTimeItem: function (data) {
      console.log('updateWorkTimeItem =>', data);
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        wmId: data.wmId ,
        groups:[{
          id: that.currentWorkTimeItem.id
        }]
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgWorkforceManagement/setGroupWM",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.closeWorkTimeItem()
          that.getDeptWorkTimeList()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>
